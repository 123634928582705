import { getHeaders } from '../headers';

export const createApi = (defaultBaseUrl) => {
  return {
    delete: async (id) => {
      const response = await fetch(`${defaultBaseUrl}/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },
    update: async (id, survey_participant) => {
      const response = await fetch(`${defaultBaseUrl}/${id}`, {
        method: 'PATCH',
        headers: getHeaders(),
        body: JSON.stringify({ survey_participant }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw {
          status: response.status,
          message: errorData.message,
          redirect_url: errorData.redirect_url,
        };
      }

      return response.json();
    },
    create: async (data) => {
      const response = await fetch(`${defaultBaseUrl}`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },
    complete: async (id) => {
      const response = await fetch(`${defaultBaseUrl}/${id}/complete`, {
        method: 'PUT',
        headers: getHeaders(),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },
  };
};
