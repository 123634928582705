import React, { useEffect, useState } from 'react';
import { Stack, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, TableSortLabel, Button, IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import capitalize from 'lodash/capitalize';

const sortSurveys = (surveys, property, order) => {
  if (!surveys) return [];
  const surveysCopy = [...surveys];
  let compareFunction;

  const parseFraction = (fraction) => {
    const [numerator, denominator] = fraction.split('/').map(Number);
    return numerator / denominator;
  };

  const parsePercentage = (value) => {
    return value || 0;
  };

  switch (property) {
    case 'org_name':
    case 'name':
    case 'status':
      compareFunction = (a, b) => a[property]?.localeCompare(b[property]);
      break;
    case 'created_by':
      compareFunction = (a, b) => a[property]?.full_name?.localeCompare(b[property]?.full_name);
      break;
    case 'created_at':
      compareFunction = (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
      break;
    case 'completed_fraction':
      compareFunction = (a, b) => {
        const aValue = a[property] ? parseFraction(a[property]) : 0;
        const bValue = b[property] ? parseFraction(b[property]) : 0;
        return aValue - bValue;
      };
      break;
    case 'completed_percentage':
      compareFunction = (a, b) => {
        const aValue = a[property] ? parsePercentage(a[property]) : 0;
        const bValue = b[property] ? parsePercentage(b[property]) : 0;
        return aValue - bValue;
      };
      break;
    default:
      compareFunction = () => 0;
  }

  return surveysCopy.sort((a, b) => {
    let comparison = compareFunction(a, b);
    return order === 'asc' ? comparison : -comparison;
  });
};

const SortableTableHead = (props) => {
  const { order, orderBy, onRequestSort, showOrgColumn } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'org_name',
      numeric: false,
      label: 'Org',
      sortable: true,
      sx: { width: '40%' },
    },
    {
      id: 'name',
      numeric: false,
      label: 'Name',
      sortable: true,
      sx: { width: '40%' },
    },
    {
      id: 'created_by',
      numeric: false,
      label: 'Created by',
      sortable: true,
    },
    {
      id: 'created_at',
      numeric: false,
      label: 'Created on',
      sortable: true,
    },
    {
      id: 'status',
      numeric: false,
      label: 'Status',
      sortable: true,
      sx: { width: '50px' },
    },
    {
      id: 'completed_fraction',
      numeric: true,
      label: 'Completed (N/X)',
      sortable: true,
      sx: { width: '50px' },
    },
    {
      id: 'completed_percentage',
      numeric: true,
      label: 'Completed (%)',
      sortable: true,
      sx: { width: '50px' },
    },
    {
      id: 'actions',
      numeric: false,
      label: 'Actions',
      sortable: false,
      sx: { width: '100px' },
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells
          .filter((cell) => {
            if (!showOrgColumn) {
              return cell.id !== 'org_name';
            } else {
              return true;
            }
          })
          .map((headCell) => (
            <TableCell
              key={headCell.id}
              align={'left'}
              padding={'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              {...(headCell.sx && { sx: headCell.sx })}
            >
              {headCell.sortable ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

const DataRow = ({ survey, onEdit, onDelete, onClone, showOrgColumn }) => {
  return (
    <TableRow data-cy={`survey-${survey.id}`}>
      {showOrgColumn && <TableCell align="left">{survey.org_name}</TableCell>}
      <TableCell align="left" data-cy="survey-name">
        {survey.name}
      </TableCell>
      <TableCell align="left">{survey?.created_by?.full_name}</TableCell>
      <TableCell align="left">{new Date(survey.created_at).toLocaleDateString({}, { timeZone: 'UTC' })}</TableCell>
      <TableCell align="left">{capitalize(survey?.status)}</TableCell>
      <TableCell align="left">{survey.completed_fraction ? `${survey.completed_fraction}` : '0/0'}</TableCell>
      <TableCell align="left">{survey.completed_percentage ? `${survey.completed_percentage}%` : '0%'}</TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
          {typeof onClone === 'function' && (
            <Tooltip title="Clone">
              <IconButton aria-label="Clone" size="small" onClick={() => onClone(survey.id)} data-cy="button-clone-survey">
                <FontAwesomeIcon icon={faClone} color="#585858" />
              </IconButton>
            </Tooltip>
          )}
          {typeof onDelete === 'function' && (
            <Tooltip title="Delete">
              <IconButton aria-label="Delete" size="small" onClick={() => onDelete(survey.id)} data-cy="button-delete-survey">
                <FontAwesomeIcon icon={faTrashCan} color="#585858" />
              </IconButton>
            </Tooltip>
          )}
          {typeof onEdit === 'function' && (
            <Button variant="contained" color="secondary" onClick={() => onEdit(survey.id)}>
              Edit
            </Button>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

const DataTable = ({ surveys, onEdit, onDelete, onClone, emptyMessage, showOrgColumn = false }) => {
  const [orderBy, setOrderBy] = useState(showOrgColumn ? 'org_name' : 'created_at');
  const [order, setOrder] = useState(showOrgColumn ? 'asc' : 'desc');
  const [sortedSurveys, setSortedSurveys] = useState(surveys || []);

  useEffect(() => {
    setSortedSurveys(sortSurveys(surveys, orderBy, order));
  }, [surveys, orderBy, order]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);

    setSortedSurveys(sortSurveys(surveys, property, newOrder));
  };

  if (surveys.length > 0) {
    return (
      <TableContainer>
        <Table size={'small'} sx={{ marginTop: 4 }}>
          <SortableTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} showOrgColumn={showOrgColumn} />
          <TableBody>
            {sortedSurveys.map((survey, index) => (
              <DataRow
                showOrgColumn={showOrgColumn}
                key={`${survey.id}_${index}`}
                survey={survey}
                onEdit={onEdit}
                onClone={onClone}
                onDelete={onDelete}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return <div className="text-center">{emptyMessage}</div>;
  }
};

export default DataTable;
