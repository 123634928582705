import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DataGridPro, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarColumnsButton } from '@mui/x-data-grid-pro';
import { gridStyles } from '../../../helpers/data_grid/common_grid_styles';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Button from '@mui/material/Button';
import { useGridApiRef } from '@mui/x-data-grid-pro';

const DetailsTable = ({ schoolName, participants }) => {
  const apiRef = useGridApiRef();
  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        flex: 0.7,
        minWidth: 150,
        valueGetter: (value, row) => {
          return `${row.first_name || ''} ${row.last_name || ''}`.trim();
        },
      },
      {
        field: 'email',
        headerName: 'Email',
        type: 'string',
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'school',
        headerName: 'School',
        type: 'string',
        flex: 1,
        minWidth: 200,
      },
      {
        field: 'progress',
        headerName: 'Progress',
        type: 'string',
        width: 120,
        valueGetter: (value, row) => {
          return row.progress || null;
        },
        valueFormatter: (value) => {
          return value ? `${value}%` : '';
        },
      },
      {
        field: 'completed_at',
        headerName: 'Completed at',
        type: 'date',
        width: 160,
        valueGetter: (value, row) => {
          return row.completed_at || null;
        },
        valueFormatter: (value) => {
          return value ? dayjs(value).format('MM/DD/YYYY') : '';
        },
      },
    ],
    [participants]
  );

  const sanitizeForFilename = (str) => {
    return str
      .replace(/[/\\?%*:|"<>\s,;']/g, '_')
      .replace(/^\.+|\.+$/g, '')
      .trim();
  };

  const truncateFilename = (filename, maxLength = 240) => {
    return filename.length <= maxLength ? filename : filename.slice(0, maxLength);
  };

  const dateFormatted = () => {
    const now = new Date();
    const dateTime = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + '_' + now.getHours() + '-' + now.getMinutes();
    return dateTime.replace(/[/:]/g, '-').replace(', ', '_');
  };

  function getFileName(schoolName) {
    const schoolNameTruncated = truncateFilename(sanitizeForFilename(schoolName || 'School'));
    return `${schoolNameTruncated}_Participants_${dateFormatted()}`;
  }

  const applyIncompleteFilter = useCallback(() => {
    const filterModel = {
      items: [
        {
          field: 'completed_at',
          operator: 'isEmpty',
        },
      ],
    };
    apiRef.current.setFilterModel(filterModel);
  }, [apiRef]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <Button color="primary" startIcon={<FilterAltOutlinedIcon />} onClick={applyIncompleteFilter}>
          Incomplete
        </Button>
        <GridToolbarColumnsButton />
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{
            fileName: getFileName(schoolName),
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <DataGridPro
      rows={participants}
      columns={columns}
      sx={gridStyles}
      autoHeight={true}
      hideFooter={true}
      disableColumnReorder={true}
      disableAutosize={true}
      disableSelectionOnClick
      density={'compact'}
      sortingOrder={['asc', 'desc']}
      slots={{ toolbar: CustomToolbar }}
      apiRef={apiRef}
      initialState={{
        sorting: {
          sortModel: [
            { field: 'completed_at', sort: 'asc' },
            { field: 'name', sort: 'asc' },
          ],
        },
      }}
    />
  );
};

DetailsTable.propTypes = {
  participantsBySchool: PropTypes.object,
};

export default DetailsTable;
