import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Layout from '../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import { ConfirmDialogProvider } from '../../components/modals/confirm_dialog';
import SurveyTaker from '../../components/surveys/survey_taker';
import { setLicenseKey } from 'survey-core';

const Container = (props) => {
  const [survey, setSurvey] = useState(props.survey);
  setLicenseKey(props.surveyjs_key);
  return (
    <SurveyTaker
      surveyId={survey.id}
      surveyContent={survey.content}
      response={props.response}
      isCompleted={props.completed}
      participantId={props.participant_id}
      currentPage={props.current_page}
      token={props.token}
    />
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <ConfirmDialogProvider>
          <Container {...props}></Container>
        </ConfirmDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};

Container.propTypes = {
  participant_id: PropTypes.number.isRequired,
  current_page: PropTypes.number,
  survey: PropTypes.object.isRequired,
  response: PropTypes.object,
  completed: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  surveyjs_key: PropTypes.string.isRequired,
};
