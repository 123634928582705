import { getHeaders } from '../headers';

export const getJobLog = async (id, baseUrl = '/job_logs') => {
  const response = await fetch(`${baseUrl}/${id}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};
