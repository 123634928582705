import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { closeButtonSx } from '../../constants/dialog';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import _ from 'lodash';

const ParticipantDialog = ({ isOpen, onSave, onClose, gender_options, race_ethnicity_options, role_options, participant = null }) => {
  const title = participant ? 'Edit participant' : 'Add participant';
  const [participantData, setParticipantData] = useState(participant || {});

  useEffect(() => {
    setParticipantData(participant || {});
  }, [participant]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setParticipantData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSave = () => {
    onSave(participantData);
    setParticipantData({});
  };

  const handleOnClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    onClose();
  };

  const isEdit = () => participantData.id;

  const birthDateValue = participantData?.birth_date ? dayjs(participantData.birth_date) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog fullWidth open={isOpen} onClose={(event, reason) => handleOnClose(event, reason)} scroll="paper">
        <DialogTitle>
          {title}
          {isEdit() && <DialogContentText sx={{ fontSize: '1.2rem' }}>{participantData.email}</DialogContentText>}
        </DialogTitle>
        <IconButton aria-label="close" onClick={onClose} sx={closeButtonSx}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers={true} sx={{ maxHeight: '60vh' }}>
          <Grid container spacing={2} marginTop={1}>
            {!isEdit() && (
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  fullWidth
                  label="Email"
                  name="email"
                  size="small"
                  variant="outlined"
                  value={participantData.email || ''}
                  onChange={handleChange}
                  InputProps={isEdit() ? { readOnly: true } : {}}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="First name"
                name="first_name"
                size="small"
                variant="outlined"
                value={participantData.first_name || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Last name"
                name="last_name"
                size="small"
                variant="outlined"
                value={participantData.last_name || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel id="role-select-label">Role</InputLabel>
                <Select labelId="role-select-label" id="role" value={participantData.role || ''} label="Role" name="role" onChange={handleChange}>
                  {role_options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {_.capitalize(option)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Role Detail"
                name="role_detail"
                size="small"
                variant="outlined"
                value={participantData.role_detail || ''}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} marginTop={4}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="School"
                name="school"
                size="small"
                variant="outlined"
                value={participantData.school || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Department"
                name="department"
                size="small"
                variant="outlined"
                value={participantData.department || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Subject taught"
                name="subject_taught"
                size="small"
                variant="outlined"
                value={participantData.subject_taught || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Tenure with organization"
                name="tenure_with_organization"
                size="small"
                variant="outlined"
                value={participantData.tenure_with_organization !== null ? participantData.tenure_with_organization : ''}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} marginTop={4} marginBottom={2}>
            <Grid item xs={12}>
              <DatePicker
                label="Birth date"
                name="birth_date"
                value={birthDateValue}
                onChange={(date) => {
                  const value = date ? date.format('YYYY-MM-DD') : null;
                  if (value && dayjs(value).isValid()) {
                    setParticipantData((prevState) => ({ ...prevState, birth_date: value }));
                  }
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    required: false,
                    size: 'small',
                    InputLabelProps: { shrink: true },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel id="gender-select-label">Gender</InputLabel>
                <Select
                  labelId="gender-select-label"
                  id="gender-select"
                  value={participantData.gender || ''}
                  label="Gender"
                  name="gender"
                  onChange={handleChange}
                >
                  {gender_options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {_.capitalize(option)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel id="race-ethnicity-select-label">Race/Ethnicity</InputLabel>
                <Select
                  labelId="race-ethnicity-select-label"
                  id="race-ethnicity-select"
                  value={participantData.race_ethnicity || ''}
                  label="Race/Ethnicity"
                  name="race_ethnicity"
                  onChange={handleChange}
                >
                  {race_ethnicity_options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {_.startCase(_.toLower(option))
                        .replaceAll('Not Hispanic Or Latino', '(Not Hispanic or Latino)')
                        .replaceAll('All Other Races', '(All Other Races)')
                        .replaceAll('Or', 'or')
                        .replaceAll('Not', 'not')
                        .replaceAll('To', 'to')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Geographic Region"
                name="geographic_region"
                size="small"
                variant="outlined"
                value={participantData.geographic_region || ''}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

const participantShape = PropTypes.shape({
  id: PropTypes.number,
  email: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  role: PropTypes.string,
  school: PropTypes.string,
  department: PropTypes.string,
  subject_taught: PropTypes.string,
  tenure_with_organization: PropTypes.number,
  birth_date: PropTypes.string,
});

ParticipantDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  participant: participantShape,
};

export default ParticipantDialog;
