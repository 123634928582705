import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Layout from '../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import { ConfirmDialogProvider, useConfirmDialog } from '../../components/modals/confirm_dialog';
import { Paper, Button, Drawer } from '@mui/material';
import SurveyCollectionsNav from '../../components/survey_collections/nav';
import SurveyCollectionsTable from '../../components/survey_collections/table';
import useDrawerWidth from '../../helpers/use_drawer_width';
import { createSurveyCollection, getAssignableSurveys, updateSurveyCollection, deleteSurveyCollection } from '../../apis/survey_collections/api';
import SurveyCollectionsForm from '../../components/survey_collections/form';

const Container = (props) => {
  const { org_id, survey_collections: initialSurveyCollections, base_url, links, permission_props } = props;
  const [surveyCollections, setSurveyCollections] = useState(initialSurveyCollections);
  const [surveyCollection, setSurveyCollection] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [assignableSurveys, setAssignableSurveys] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const drawerWidth = useDrawerWidth();
  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();

  const handleAdd = () => {
    setSurveyCollection(null);
    setDrawerOpen(true);
    setIsLoading(true);
    getAssignableSurveys(base_url).then((surveys) => {
      setAssignableSurveys(surveys);
      setIsLoading(false);
    });
  };

  const handleEdit = (id) => {
    const collection = surveyCollections.find((item) => item.id === id);
    if (collection) {
      setSurveyCollection(collection);
      setDrawerOpen(true);
      setIsLoading(true);
      getAssignableSurveys(base_url).then((surveys) => {
        setAssignableSurveys(surveys);
        setIsLoading(false);
      });
    }
  };

  const handleClose = () => {
    setDrawerOpen(false);
    setAssignableSurveys([]);
    setSurveyCollection(null);
    setIsLoading(false);
    setErrors({});
  };

  const handleSave = async (surveyCollectionData) => {
    try {
      let response;
      if (surveyCollectionData.id) {
        response = await updateSurveyCollection(surveyCollectionData, base_url);
        const updatedSurveyCollections = surveyCollections.map((item) => (item.id === response.id ? response : item));
        setSurveyCollections(updatedSurveyCollections);
      } else {
        response = await createSurveyCollection(surveyCollectionData, base_url);
        setSurveyCollections([...surveyCollections, response]);
      }

      handleClose();
      setErrors({});
    } catch (error) {
      setErrors(error);
    }
  };

  const confirmDelete = (id) => {
    showConfirmDialog({
      title: 'Delete Survey',
      description: 'Deleting this survey will make the questionnaires available for future use.',
      actions: [
        {
          label: 'Cancel',
          onClick: () => hideConfirmDialog(),
          variant: 'text',
          color: 'primary',
        },
        {
          label: 'Delete',
          onClick: () => handleDelete(id),
          variant: 'contained',
          color: 'primary',
          autoFocus: false,
        },
      ],
      maxWidth: 'xs',
    });
  };

  const handleDelete = async (id) => {
    try {
      await deleteSurveyCollection(id, base_url);
      setSurveyCollections(surveyCollections.filter((item) => item.id !== id));
      hideConfirmDialog();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <SurveyCollectionsNav links={links} permissionProps={permission_props} activeTab="survey_collections" />
      <Paper className="p-3 mt-4">
        {permission_props.can_create_survey_collection && (
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Button variant="contained" color="primary" onClick={handleAdd}>
              New Survey
            </Button>
          </div>
        )}
        <SurveyCollectionsTable
          survey_collections={surveyCollections}
          onEdit={handleEdit}
          onDelete={confirmDelete}
          permissionProps={permission_props}
          baseUrl={base_url}
        />
      </Paper>
      <Drawer
        open={drawerOpen}
        onClose={handleClose}
        anchor="right"
        PaperProps={{
          sx: {
            width: drawerWidth,
          },
        }}
      >
        <SurveyCollectionsForm
          surveyCollection={surveyCollection}
          onSave={handleSave}
          onClose={handleClose}
          assignableSurveys={assignableSurveys}
          errors={errors}
          isLoading={isLoading}
        />
      </Drawer>
    </>
  );
};

Container.propTypes = {
  org_id: PropTypes.number.isRequired,
  survey_collections: PropTypes.array.isRequired,
  base_url: PropTypes.string.isRequired,
  links: PropTypes.object.isRequired,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <ConfirmDialogProvider>
          <Container {...props} />
        </ConfirmDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};
