import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Layout from '../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import { ConfirmDialogProvider, useConfirmDialog } from '../../components/modals/confirm_dialog';
import { Paper, Button, Drawer } from '@mui/material';
import SurveyCollectionsNav from '../../components/survey_collections/nav';
import SurveyViewersForm from '../../components/survey_viewers/form';
import SurveyViewersTable from '../../components/survey_viewers/table';
import useDrawerWidth from '../../helpers/use_drawer_width';
import { createOrEditSurveyViewer, deleteSurveyViewer } from '../../apis/survey_viewers/api';

const Container = (props) => {
  const { org_id, survey_viewers: initialSurveyViewers, all_users, base_url, links, permission_props } = props;
  const [surveyViewers, setSurveyViewers] = React.useState(initialSurveyViewers);
  const [editingSurveyViewer, setEditingSurveyViewer] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const drawerWidth = useDrawerWidth();
  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();

  const handleClose = () => {
    setDrawerOpen(false);
    setIsLoading(false);
    setErrors({});
    setEditingSurveyViewer(null);
  };

  const handleSave = async (surveyViewer) => {
    const response = await createOrEditSurveyViewer(surveyViewer, base_url);
    if (response.errors) {
      setErrors(response.errors);
    } else {
      const idx = surveyViewers.findIndex((surveyViewer) => surveyViewer.id === response.id);
      if (idx === -1) {
        setSurveyViewers([...surveyViewers, response]);
      } else {
        const newSurveyViewers = [...surveyViewers.slice(0, idx), response, ...surveyViewers.slice(idx + 1)];
        setSurveyViewers(newSurveyViewers);
      }
      handleClose();
    }
  };

  const editSurveyViewer = (surveyViewer) => {
    setEditingSurveyViewer(surveyViewer);
    setDrawerOpen(true);
  };

  const onDelete = (id) => {
    showConfirmDialog({
      title: 'Delete Confirmation',
      description: 'Are you sure you want to delete this survey viewer?',
      actions: [
        {
          label: 'Cancel',
          variant: 'text',
          color: 'primary',
          onClick: () => {
            hideConfirmDialog();
          },
        },
        {
          label: 'Delete',
          variant: 'contained',
          color: 'primary',
          autoFocus: false,
          onClick: async () => {
            const response = await deleteSurveyViewer(id, base_url);
            const idx = surveyViewers.findIndex((surveyViewer) => surveyViewer.id === id);
            setSurveyViewers([...surveyViewers.slice(0, idx), ...surveyViewers.slice(idx + 1)]);
            hideConfirmDialog();
          },
        },
      ],
    });
  };

  const eligibleUsers = () => {
    const userIds = surveyViewers.map((sv) => sv.user.id);
    return all_users.filter((user) => !userIds.includes(user.id));
  };

  return (
    <>
      <SurveyCollectionsNav links={links} permissionProps={permission_props} activeTab="survey_viewers" />
      <Button variant="contained" onClick={(e) => setDrawerOpen(true)} sx={{ mt: 3, mb: 3 }}>
        Add
      </Button>
      <SurveyViewersTable surveyViewers={surveyViewers} onDelete={onDelete} onEdit={editSurveyViewer} />
      <Drawer
        open={drawerOpen}
        onClose={handleClose}
        anchor="right"
        PaperProps={{
          sx: {
            width: drawerWidth,
          },
        }}
      >
        <SurveyViewersForm
          surveyViewer={editingSurveyViewer}
          allUsers={all_users}
          eligibleUsers={eligibleUsers()}
          onSave={handleSave}
          onClose={() => {
            setDrawerOpen(false);
          }}
          errors={errors}
          isLoading={false}
        />
      </Drawer>
    </>
  );
};

Container.propTypes = {
  org_id: PropTypes.number.isRequired,
  survey_viewers: PropTypes.array.isRequired,
  base_url: PropTypes.string.isRequired,
  links: PropTypes.object.isRequired,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <ConfirmDialogProvider>
          <Container {...props} />
        </ConfirmDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};
