import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import { ConfirmDialogProvider } from '../../components/modals/confirm_dialog';
import { formatDateFriendly } from '../../helpers/date_utils';
import { Typography, Container } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-regular-svg-icons';

const renderCompleted = (survey, participant) => {
  return (
    <>
      <FontAwesomeIcon icon={faCircleCheck} style={{ color: '#67bbb9', fontSize: '5rem', marginBottom: '1rem' }} />
      <Typography variant="h3" align="center" gutterBottom>
        Thank you for participating!
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        You have successfully completed the survey titled <strong>{survey.name}</strong>.
      </Typography>
      <Typography
        variant="body2"
        align="center"
        gutterBottom
        sx={{
          paddingBottom: '1.5rem',
          position: 'relative',
          '&::after': {
            content: '""',
            display: 'block',
            width: '30%',
            height: '4px',
            backgroundColor: '#ccc',
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
          },
        }}
      >
        Completion Date: {formatDateFriendly(participant.completed_at)}
      </Typography>
    </>
  );
};

const renderClosed = (survey, participant) => {
  return (
    <>
      <FontAwesomeIcon icon={faCircleXmark} style={{ color: '#ccc', fontSize: '5rem', marginBottom: '1rem' }} />
      <Typography variant="h3" align="center" gutterBottom>
        Survey Closed!
      </Typography>
      <Typography
        variant="body1"
        align="center"
        gutterBottom
        sx={{
          paddingBottom: '1.5rem',
          position: 'relative',
          '&::after': {
            content: '""',
            display: 'block',
            width: '30%',
            height: '4px',
            backgroundColor: '#ccc',
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
          },
        }}
      >
        Thank you for your interest in participating. Unfortunately this survey ({survey.name}) is closed and no longer accepting responses. We
        appreciate your understanding.
      </Typography>
    </>
  );
};

const renderContent = (survey, participant) => {
  if (participant.completed_at) {
    return renderCompleted(survey, participant);
  } else if (survey.status === 'closed') {
    return renderClosed(survey, participant);
  } else {
    // this should never happen
    return <></>;
  }
};

const SurveyAlert = ({ survey, participant }) => (
  <Container
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'calc(100vh - 210px)',
      maxWidth: '700px',
    }}
  >
    {renderContent(survey, participant)}
  </Container>
);

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <ConfirmDialogProvider>
          <SurveyAlert {...props} />
        </ConfirmDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};

Container.propTypes = {
  survey: PropTypes.object,
  participant: PropTypes.object,
};
