import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography, Link, TextField, InputAdornment, IconButton } from '@mui/material';
import ToggleSurveyStatus from './toggle_survey_status';
import ToggleSurveyPublicMode from './toggle_survey_public_mode';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const SurveyNav = ({ survey, activeTab, links, baseUrl, inCompleteCount, onSurveyStatusChange, hasResponses }) => {
  const [publicUrl, setPublicUrl] = useState(survey.public_mode ? `${window.location.origin}/surveys/${survey.public_token}` : null);

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(publicUrl);
  };

  const handlePublicStatusChange = (updatedSurvey) => {
    if (updatedSurvey.public_mode) {
      setPublicUrl(`${window.location.origin}/surveys/${updatedSurvey.public_token}`);
    } else {
      setPublicUrl(null);
    }
  };

  return (
    <div className="project-nav surveys">
      <Typography variant="h1">{survey.name}</Typography>
      {publicUrl && (
        <TextField
          fullWidth
          size="small"
          label="Public Survey URL"
          value={publicUrl}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleCopyUrl} edge="end">
                  <ContentCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ mt: 2 }}
        />
      )}
      <div className="d-flex justify-content-between align-items-center">
        <Stack direction="row">
          <Link href={links.designer} className={activeTab === 'designer' ? 'active' : ''}>
            Designer
          </Link>
          <Link href={links.preview} className={activeTab === 'preview' ? 'active' : ''}>
            Preview
          </Link>
          <Link href={links.participants} className={activeTab === 'participants' ? 'active' : ''}>
            Participants
          </Link>
        </Stack>
        <div>
          <ToggleSurveyStatus survey={survey} baseUrl={baseUrl} inCompleteCount={inCompleteCount} onStatusChange={onSurveyStatusChange} />
          <ToggleSurveyPublicMode
            survey={survey}
            baseUrl={baseUrl}
            onPublicStatusChange={handlePublicStatusChange}
            initialHasResponses={hasResponses}
          />
        </div>
      </div>
      <div className="divider" />
    </div>
  );
};

SurveyNav.propTypes = {
  survey: PropTypes.object.isRequired,
  activeTab: PropTypes.string.isRequired,
  links: PropTypes.object.isRequired,
  onSurveyStatusChange: PropTypes.func,
};

export default SurveyNav;
