import { useTheme, useMediaQuery } from '@mui/material';

const useDrawerWidth = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  if (isXs || isSm) return '80%';
  if (isMd) return '60%';
  if (isLg) return '40%';
  if (isXl) return '30%';

  return '80%'; // Default width
};

export default useDrawerWidth;
