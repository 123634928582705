import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import { ConfirmDialogProvider } from '../../components/modals/confirm_dialog';
import { Paper } from '@mui/material';
import SurveyCollectionsNav from '../../components/survey_collections/nav';

const Container = (props) => {
  const { org_id, survey_collection, base_url, show_tableau, tableau_url, jwt, include_school_name, school_name, links, permission_props } = props;

  return (
    <>
      <SurveyCollectionsNav links={links} permissionProps={permission_props} activeTab="data_analysis" backUrl={links.data_analysis} />
      <Paper className="p-3 mt-4">
        {show_tableau && (
          <tableau-viz id="tableauViz" style={{ height: 1000 }} src={tableau_url} token={jwt}>
            <viz-parameter name="orgid" value={org_id}></viz-parameter>
            {include_school_name && <viz-parameter name="schoolname" value={school_name}></viz-parameter>}
          </tableau-viz>
        )}
      </Paper>
    </>
  );
};

Container.propTypes = {
  org_id: PropTypes.number,
  survey_collection: PropTypes.object,
  base_url: PropTypes.string,
  links: PropTypes.object,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <ConfirmDialogProvider>
          <Container {...props} />
        </ConfirmDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};
