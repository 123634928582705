import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@mui/material';
import { useConfirmDialog } from '../modals/confirm_dialog';
import { trackCzEvent } from '../../helpers/track_cz_event';
import { createApi } from '../../apis/surveys/api';

const ToggleSurveyStatus = ({ survey, baseUrl, inCompleteCount, onStatusChange = null }) => {
  const [isOpen, setIsOpen] = useState(survey.status === 'open');
  const [isUpdating, setIsUpdating] = useState(false);
  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();
  const api = createApi(baseUrl);

  const confirmClosure = () => {
    return new Promise((resolve, reject) => {
      const participantWord = inCompleteCount === 1 ? 'participant' : 'participants';

      showConfirmDialog({
        title: 'Are you sure you want to close this Survey?',
        description: `Closing this survey will result in ${inCompleteCount} ${participantWord} being unable to submit their survey. Are you sure you want to proceed?`,
        actions: [
          {
            label: 'Cancel',
            onClick: () => {
              hideConfirmDialog();
              reject();
            },
            variant: 'contained',
            color: 'secondary',
          },
          {
            label: 'Yes, Close Survey',
            onClick: () => {
              hideConfirmDialog();
              resolve();
            },
            variant: 'contained',
            color: 'primary',
            autoFocus: false,
          },
        ],
        maxWidth: 'sm',
      });
    });
  };

  const handleToggle = async () => {
    const newStatus = !isOpen;
    const statusString = newStatus ? 'open' : 'closed';

    // optimistic update
    setIsOpen(newStatus);
    setIsUpdating(true);

    if (statusString === 'closed' && inCompleteCount > 0) {
      try {
        await confirmClosure();
      } catch {
        // user cancels
        setIsOpen(!newStatus);
        setIsUpdating(false);
        return;
      }
    }

    try {
      const updatedSurvey = await api.update(survey.id, { status: statusString });
      setIsOpen(updatedSurvey.status === 'open');

      // callback
      if (onStatusChange && typeof onStatusChange === 'function') {
        onStatusChange(updatedSurvey.status);
      }

      trackCzEvent(
        statusString === 'open' ? 'CAREEnable:Template' : 'CAREDisable:Template',
        `User ${statusString === 'open' ? 'enabled' : 'disabled'} template ${survey.id}`,
        1
      );
    } catch (error) {
      console.error('Failed to update survey status:', error);
      setIsOpen(!newStatus);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <FormControlLabel
      control={<Switch checked={isOpen} onChange={handleToggle} id="survey_status" />}
      label={<strong>{isOpen ? 'OPEN' : 'CLOSED'}</strong>}
    />
  );
};

ToggleSurveyStatus.propTypes = {
  survey: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
  inCompleteCount: PropTypes.number.isRequired,
  onStatusChange: PropTypes.func,
};

export default ToggleSurveyStatus;
