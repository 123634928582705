import { getHeaders } from '../headers';

export const createApi = (defaultBaseUrl) => {
  return {
    delete: async (id) => {
      const response = await fetch(`${defaultBaseUrl}/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },

    create: async (survey) => {
      const response = await fetch(`${defaultBaseUrl}`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ survey }),
      });

      if (!response.ok) {
        return response.json().then((errors) => {
          throw errors;
        });
      }
      return response.json();
    },

    update: async (id, survey) => {
      const response = await fetch(`${defaultBaseUrl}/${id}`, {
        method: 'PATCH',
        headers: getHeaders(),
        body: JSON.stringify({ survey }),
      });

      if (!response.ok) {
        return response.json().then((errors) => {
          throw errors;
        });
      }
      return response.json();
    },

    clone: async (id, orgId) => {
      const response = await fetch(`${defaultBaseUrl}/${id}/clone_survey`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({
          org_id: orgId,
        }),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },

    uploadParticipantsCsv: async (id, file) => {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch(`${defaultBaseUrl}/${id}/upload_participants_csv`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('[name=csrf-token]')?.content || 'test',
        },
        body: formData,
      });

      if (!response.ok) {
        return response.json().then((errors) => {
          throw errors;
        });
      }
      return response.json();
    },

    sendEmails: async (id, email, selectedParticipants = []) => {
      const response = await fetch(`${defaultBaseUrl}/${id}/send_emails`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ email, selected_participants: selectedParticipants }),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },

    fetchEmailDispatchStatus: async (id) => {
      const response = await fetch(`${defaultBaseUrl}/${id}/email_dispatch_status`, {
        method: 'GET',
        headers: getHeaders(),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },

    fetchParticipants: async (id) => {
      const response = await fetch(`${defaultBaseUrl}/${id}/all_participants`, {
        method: 'GET',
        headers: getHeaders(),
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },

    bulkDeleteParticipants: async (id) => {
      const response = await fetch(`${defaultBaseUrl}/${id}/bulk_delete_participants`, {
        method: 'DELETE',
        headers: getHeaders(),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },

    getOtherOrgSurveys: async (id) => {
      const response = await fetch(`${defaultBaseUrl}?excluded_org_id=${id}`, {
        method: 'GET',
        headers: getHeaders(),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },

    setPublicStatus: async (id, isPublic) => {
      const response = await fetch(`${defaultBaseUrl}/${id}/set_public_status`, {
        method: 'PATCH',
        headers: getHeaders(),
        body: JSON.stringify({ is_public: isPublic }),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },
  };
};

export const getSurvey = async (id, baseUrl = '/surveys') => {
  const response = await fetch(`${baseUrl}/${id}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};
