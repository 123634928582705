import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography, Link } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

const SurveyCollectionsNav = ({ activeTab, links, permissionProps, backUrl = null }) => {
  const { can_view_survey_collections, can_view_surveys, can_view_data_analysis, can_view_survey_viewers } = permissionProps;

  return (
    <div className="project-nav surveys">
      <Typography variant="h1">Surveys</Typography>
      <div className="d-flex justify-content-between align-items-center">
        <Stack direction="row">
          {can_view_survey_collections && (
            <Link href={links.survey_collections} className={activeTab === 'survey_collections' ? 'active' : ''}>
              Surveys
            </Link>
          )}
          {can_view_surveys && (
            <Link href={links.surveys} className={activeTab === 'surveys' ? 'active' : ''}>
              Questionnaires
            </Link>
          )}
          {can_view_data_analysis && (
            <Link href={links.data_analysis} className={activeTab === 'data_analysis' ? 'active' : ''}>
              Data Analysis
            </Link>
          )}
          {can_view_survey_viewers && (
            <Link href={links.survey_viewers} className={activeTab === 'survey_viewers' ? 'active' : ''}>
              Report Viewers
            </Link>
          )}
        </Stack>
      </div>
      <div className="divider" />
      {backUrl && (
        <Link href={backUrl}>
          <FontAwesomeIcon icon={faChevronLeft} style={{ color: '#67bbb9', marginRight: 5 }} />
          Back
        </Link>
      )}
    </div>
  );
};

SurveyCollectionsNav.propTypes = {
  activeTab: PropTypes.string.isRequired,
  links: PropTypes.object.isRequired,
};

export default SurveyCollectionsNav;
