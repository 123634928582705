import React, { useEffect, useState } from 'react';
import { Stack, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, TableSortLabel, Button, IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faTrashCan, faPencil } from '@fortawesome/pro-regular-svg-icons';
import capitalize from 'lodash/capitalize';
import ActionMenuButton from '../shared/action_menu_button';

const sortSurveyViewers = (surveyViewers, property, order) => {
  if (!surveyViewers) return [];
  const surveyViewersCopy = [...surveyViewers];

  const parseFraction = (fraction) => {
    const [numerator, denominator] = fraction.split('/').map(Number);
    return numerator / denominator;
  };

  const parsePercentage = (value) => {
    return value || 0;
  };

  let compareFunction = (a, b) => {
    if (a < b) {
      return -1;
    } else if (a == b) {
      return 0;
    } else {
      return 1;
    }
  };

  return surveyViewersCopy.sort((a, b) => {
    let comparison = compareFunction(a, b);
    return order === 'asc' ? comparison : -comparison;
  });
};

const SortableTableHead = (props) => {
  const { order, orderBy, onRequestSort, showOrgColumn } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: 'name',
      numeric: false,
      label: 'Name',
      sortable: true,
      sx: { width: '200px' },
    },
    {
      id: 'email',
      numeric: false,
      label: 'Email',
      sortable: true,
      sx: { width: '200px' },
    },
    {
      id: 'description',
      numeric: false,
      label: 'Description',
      sortable: true,
    },
    {
      id: 'allow_access_to_all_schools',
      numeric: false,
      label: 'All Schools',
      sortable: true,
      sx: { width: '150px' },
    },
    {
      id: 'single_school_name',
      numeric: true,
      label: 'Single School Name',
      sortable: true,
      sx: { width: '200px' },
    },
    {
      id: 'actions',
      numeric: false,
      label: 'Actions',
      sortable: false,
      sx: { width: '100px' },
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells
          .filter((cell) => {
            if (!showOrgColumn) {
              return cell.id !== 'org_name';
            } else {
              return true;
            }
          })
          .map((headCell) => (
            <TableCell
              key={headCell.id}
              align={'left'}
              padding={'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              {...(headCell.sx && { sx: headCell.sx })}
            >
              {headCell.sortable ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

const DataRow = ({ surveyViewer, onEdit, onDelete, onClone }) => {
  const getMenuItems = (surveyViewerId) => {
    const menuItems = [];

    menuItems.push({
      label: 'Delete',
      icon: faTrashCan,
      onClick: () => onDelete(surveyViewerId),
    });

    menuItems.push({
      label: 'Edit',
      icon: faPencil,
      onClick: () => onEdit(surveyViewer),
    });

    return menuItems;
  };
  return (
    <TableRow data-cy={`survey-${surveyViewer.id}`}>
      <TableCell align="left">{surveyViewer.user.full_name}</TableCell>
      <TableCell align="left">{surveyViewer.user.email}</TableCell>
      <TableCell align="left">{surveyViewer.description}</TableCell>
      <TableCell align="left">{surveyViewer.allow_access_to_all_schools ? 'Yes' : 'No'}</TableCell>
      <TableCell align="left">{surveyViewer.single_school_name}</TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
          <ActionMenuButton menuItems={getMenuItems(surveyViewer.id)} />
        </Stack>
      </TableCell>
    </TableRow>
  );
};

const DataTable = ({ surveyViewers, onEdit, onDelete, onClone, emptyMessage, showOrgColumn = false }) => {
  const [orderBy, setOrderBy] = useState(showOrgColumn ? 'org_name' : 'created_at');
  const [order, setOrder] = useState(showOrgColumn ? 'asc' : 'desc');
  const [sortedSurveyViewers, setSortedSurveyViewers] = useState(surveyViewers || []);

  useEffect(() => {
    setSortedSurveyViewers(sortSurveyViewers(surveyViewers, orderBy, order));
  }, [surveyViewers, orderBy, order]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);

    setSortedSurveyViewers(sortSurveyViewers(surveyViewers, property, newOrder));
  };

  if (surveyViewers.length > 0) {
    return (
      <TableContainer>
        <Table size={'small'} sx={{ marginTop: 4 }}>
          <SortableTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} showOrgColumn={showOrgColumn} />
          <TableBody>
            {sortedSurveyViewers.map((surveyViewer, index) => (
              <DataRow
                showOrgColumn={showOrgColumn}
                key={`${surveyViewer.id}_${index}`}
                surveyViewer={surveyViewer}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return <div className="text-center">{emptyMessage}</div>;
  }
};

export default DataTable;
