import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { gridStyles } from '../../../helpers/data_grid/common_grid_styles';
import { Typography, Box } from '@mui/material';
import DetailsTable from '../../../components/survey_collections/participants/details_table';

const SummaryTable = ({ participantsBySchool }) => {
  const [selectedSchool, setSelectedSchool] = useState(null);

  const rows = useMemo(() => {
    return Object.entries(participantsBySchool).map(([school, participants], index) => ({
      id: index,
      school: school,
      participantCount: participants.length,
      completedCount: participants.filter((p) => p.completed_at).length,
    }));
  }, [participantsBySchool]);

  useEffect(() => {
    if (rows.length === 1) {
      setSelectedSchool(rows[0].school);
    }
  }, [rows]);

  const columns = useMemo(
    () => [
      {
        field: 'school',
        headerName: 'School',
        type: 'string',
        flex: 1,
        minWidth: 200,
        renderCell: (params) => (
          <Typography
            component="span"
            sx={{
              color: 'primary.main',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            {params.value}
          </Typography>
        ),
      },
      {
        field: 'participantCount',
        headerName: 'Participants',
        type: 'number',
        width: 100,
        align: 'center',
      },
      {
        field: 'completedCount',
        headerName: 'Completed',
        type: 'number',
        width: 100,
        align: 'center',
      },
      {
        field: 'completionRate',
        headerName: 'Completion Rate',
        width: 200,
        type: 'number',
        align: 'center',
        valueGetter: (value, row) => {
          const rate = (row.completedCount / row.participantCount) * 100;
          return rate || 0;
        },
        valueFormatter: (value) => {
          return value ? `${Math.round(value)}%` : '0%';
        },
      },
    ],
    [participantsBySchool]
  );

  const handleRowClick = (params) => {
    setSelectedSchool(params.row.school);
  };

  const getParticipantsForSelectedSchool = () => {
    if (selectedSchool === '') {
      return participantsBySchool[''] || [];
    }
    return participantsBySchool[selectedSchool] || [];
  };

  return (
    <Box sx={{ marginTop: 4 }}>
      <Typography variant="h6" sx={{ fontWeight: '500' }}>
        Summary
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom: 2 }}>
        Click on a row to view participant details
      </Typography>
      <DataGridPro
        rows={rows}
        columns={columns}
        autoHeight={true}
        hideFooter={true}
        disableColumnReorder={true}
        disableAutosize={true}
        density={'compact'}
        sortingOrder={['asc', 'desc']}
        onRowClick={handleRowClick}
        initialState={{
          sorting: {
            sortModel: [{ field: 'school', sort: 'asc' }],
          },
        }}
        sx={{
          ...gridStyles,
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: '#e3f2fd !important',
          },
          '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: '#e3f2fd !important',
          },
        }}
      />
      {selectedSchool && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: '500', marginBottom: 1 }}>
            Details for {selectedSchool}
          </Typography>
          <DetailsTable schoolName={selectedSchool} participants={getParticipantsForSelectedSchool()} />
        </Box>
      )}
    </Box>
  );
};

SummaryTable.propTypes = {
  participantsBySchool: PropTypes.object,
};

export default SummaryTable;
