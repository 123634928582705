import React, { useState } from 'react';
import { Stack, Button, Menu, MenuItem, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons';

const ActionMenuButton = ({ menuItems, sx }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const menuButtonStyle = {
    height: '36.5px',
    minWidth: 'auto',
  };
  const iconStyle = {
    minWidth: '20px',
    textAlign: 'center',
  };

  return (
    menuItems &&
    menuItems.length > 0 && (
      <>
        <Button
          id="menu-button"
          aria-controls={open ? 'positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleMenuClick}
          variant="contained"
          color="secondary"
          style={menuButtonStyle}
          sx={sx}
          size="small"
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </Button>
        <Menu
          id="positioned-menu"
          aria-labelledby="menu-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {menuItems.map((item, index) => (
            <MenuItem id={item.label} key={index} onClick={item.onClick} disabled={item.disabled} {...item.dataAttributes}>
              <Stack direction="row" spacing={1} alignItems="center">
                {item.icon && <FontAwesomeIcon icon={item.icon} style={iconStyle} />}
                <Typography variant="body1">{item.label}</Typography>
              </Stack>
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  );
};

ActionMenuButton.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object),
  sx: PropTypes.object,
};

export default ActionMenuButton;
