import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import { useConfirmDialog } from '../modals/confirm_dialog';
import { createApi } from '../../apis/surveys/api';

const ToggleSurveyPublicMode = ({ survey, baseUrl, onPublicStatusChange, initialHasResponses }) => {
  const [isPublic, setIsPublic] = useState(survey.public_mode);
  const [isUpdating, setIsUpdating] = useState(false);
  const [hasResponses, setHasResponses] = useState(initialHasResponses);
  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();
  const api = createApi(baseUrl);

  const handleToggle = async () => {
    if (hasResponses) {
      showConfirmDialog({
        title: 'Cannot Change Public Status',
        description: 'This survey already has responses. The public status cannot be changed once responses have been received.',
        actions: [
          {
            label: 'OK',
            onClick: hideConfirmDialog,
            variant: 'contained',
            color: 'primary',
          },
        ],
        maxWidth: 'sm',
      });
      return;
    }

    const newStatus = !isPublic;

    // Optimistic update
    setIsPublic(newStatus);
    setIsUpdating(true);

    showConfirmDialog({
      title: `Confirm ${newStatus ? 'Public' : 'Invite Only'} Mode`,
      description: `Are you sure you want to make this survey ${newStatus ? 'public' : 'invite only'}?`,
      actions: [
        {
          label: 'Cancel',
          onClick: () => {
            setIsPublic(!newStatus);
            setIsUpdating(false);
            hideConfirmDialog();
          },
          variant: 'text',
          color: 'primary',
        },
        {
          label: 'Confirm',
          onClick: async () => {
            try {
              const updatedSurvey = await api.setPublicStatus(survey.id, newStatus);
              setIsPublic(updatedSurvey.public_mode);
              setHasResponses(updatedSurvey.has_responses);
              if (onPublicStatusChange) {
                onPublicStatusChange(updatedSurvey);
              }
            } catch (error) {
              console.error('Failed to update survey public status:', error);
              setIsPublic(!newStatus);
            } finally {
              setIsUpdating(false);
              hideConfirmDialog();
            }
          },
          variant: 'contained',
          color: 'primary',
          autoFocus: false,
        },
      ],
      maxWidth: 'sm',
    });
  };

  return (
    <Tooltip title={survey.has_responses ? 'Public status cannot be changed once responses have been received' : ''}>
      <span>
        <FormControlLabel
          control={<Switch checked={isPublic} onChange={handleToggle} disabled={isUpdating || survey.has_responses} />}
          label={<strong>{isPublic ? 'PUBLIC' : 'INVITE ONLY'}</strong>}
        />
      </span>
    </Tooltip>
  );
};

ToggleSurveyPublicMode.propTypes = {
  survey: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
  onPublicStatusChange: PropTypes.func,
};

export default ToggleSurveyPublicMode;
