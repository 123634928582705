import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { closeButtonSx } from '../../constants/dialog';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

const EmailDialog = ({ title, description, category, mailMergeVars, isOpen, onClose, onSend }) => {
  const getInitialSubject = (category) => {
    if (category === 'survey_invitation') {
      return 'Invitation to participate in a survey';
    }
    if (category === 'survey_reminder') {
      return 'Reminder to participate in a survey';
    }
    return '';
  };

  const getInitialBody = (category) => {
    if (category === 'survey_invitation') {
      return (
        'Hi [first_name], \n\n' +
        'You are invited to participate in our survey. ' +
        'Please let us know what you think below. Thanks for participating!'
      );
    }
    if (category === 'survey_reminder') {
      return (
        'Hi [first_name], \n\n' +
        'This is a reminder to complete a survey we shared earlier. ' +
        'We value your feedback and would appreciate your participation. Please let us know what you think below.'
      );
    }
    return '';
  };

  const [subject, setSubject] = useState(() => getInitialSubject(category));
  const [body, setBody] = useState(() => getInitialBody(category));

  const handleSend = () => {
    onSend({ subject, body, category });
    setSubject(getInitialSubject(category));
    setBody(getInitialBody(category));
  };

  const handleOnClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    onClose();
  };

  const mailMergeToolTipMsg = `
    <div>
      Supported mail merge variables:<br/>
      ${mailMergeVars.map((name) => `[ ${name} ]<br/>`).join('')}
    </div>
  `;

  return (
    <Dialog fullWidth open={isOpen} onClose={(event, reason) => handleOnClose(event, reason)} disableEscapeKeyDown={true}>
      <DialogTitle>
        {title}
        <Typography variant="body1" style={{ marginTop: '5px' }}>
          {description}
        </Typography>
      </DialogTitle>
      <IconButton aria-label="close" onClick={onClose} sx={closeButtonSx}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Subject"
              name="subject"
              size="small"
              variant="outlined"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={8}
              label="Body"
              name="body"
              size="small"
              variant="outlined"
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />
          </Grid>
          {mailMergeVars.length > 0 && (
            <Grid item xs={12} style={{ paddingTop: 3 }}>
              <Tooltip
                TransitionComponent={Zoom}
                placement="top-start"
                title={<div dangerouslySetInnerHTML={{ __html: mailMergeToolTipMsg }} />}
                PopperProps={{
                  style: { opacity: 1 },
                }}
                arrow
              >
                <a href="#" className="link-default hover-underlined d-inline-flex">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <FontAwesomeIcon icon={faCircleInfo} color="#67bbb9" />
                    <span>Mail merge info</span>
                  </Stack>
                </a>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSend}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EmailDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  category: PropTypes.string.isRequired,
  mailMergeVars: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};

export default EmailDialog;
