import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../layouts/application';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import { ConfirmDialogProvider } from '../../components/modals/confirm_dialog';
import SummaryTable from '../../components/survey_collections/participants/summary_table';
import { Typography, Box, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

const Container = (props) => {
  const { participants_by_school, survey_collection, back_url } = props;
  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton
          href={back_url}
          aria-label="back"
          sx={{
            color: '#67bbb9',
            marginRight: 1,
            '&:hover': {
              backgroundColor: 'rgba(103, 187, 185, 0.04)',
            },
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: '1rem' }} />
        </IconButton>
        <Typography variant="h5" sx={{ fontWeight: '500' }}>
          {`${survey_collection.name} - Participants`}
        </Typography>
      </Box>
      <SummaryTable participantsBySchool={participants_by_school} />
    </>
  );
};

Container.propTypes = {
  participants_by_school: PropTypes.object,
  survey_collection: PropTypes.object,
  back_url: PropTypes.string.isRequired,
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <ThemeProvider theme={themeAttuned}>
        <ConfirmDialogProvider>
          <Container {...props} />
        </ConfirmDialogProvider>
      </ThemeProvider>
    </Layout>
  );
};
