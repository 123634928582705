import { getHeaders } from '../headers';

export const createOrEditSurveyViewer = async (survey_viewer, baseUrl) => {
  let response;
  if (survey_viewer.id) {
    response = await fetch(`${baseUrl}/${survey_viewer.id}`, {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify({ survey_viewer }),
    });
  } else {
    response = await fetch(`${baseUrl}`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ survey_viewer }),
    });
  }

  return response.json();
};

export const deleteSurveyViewer = async (id, baseUrl) => {
  const response = await fetch(`${baseUrl}/${id}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  return response.json();
};
