import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Stack, TextField, Button, Typography, FormControl, Select, MenuItem, OutlinedInput, Checkbox, ListItemText } from '@mui/material';

const SurveyCollectionsForm = ({ surveyCollection, onSave, onClose, assignableSurveys, errors, isLoading = false }) => {
  const [name, setName] = useState(surveyCollection?.name || '');
  const [description, setDescription] = useState(surveyCollection?.description || '');
  const [tableauUrl, setTableauUrl] = useState(surveyCollection?.tableau_url || '');
  const [selectedSurveys, setSelectedSurveys] = useState([]);
  const [allSurveys, setAllSurveys] = useState([]);

  useEffect(() => {
    if (surveyCollection && assignableSurveys.length >= 0) {
      const assignedSurveys = surveyCollection.surveys || [];
      const combinedSurveys = [...assignedSurveys, ...assignableSurveys.filter((s) => !assignedSurveys.some((as) => as.id === s.id))];
      setAllSurveys(combinedSurveys);
      setSelectedSurveys(assignedSurveys.map((s) => s.id));
    } else if (assignableSurveys.length > 0) {
      setAllSurveys(assignableSurveys);
    }
  }, [surveyCollection, assignableSurveys]);

  const handleSave = async (e) => {
    e.preventDefault();
    const surveyCollectionData = { name, description, tableau_url: tableauUrl, survey_ids: selectedSurveys };

    if (surveyCollection?.id) {
      surveyCollectionData.id = surveyCollection.id;
    }
    onSave(surveyCollectionData);
  };

  const handleSurveysChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedSurveys(typeof value === 'string' ? value.split(',') : value);
  };

  const hasErrors = (prop) => errors && errors[prop]?.length > 0;

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Box sx={{ overflowY: 'auto', flexGrow: 1, padding: 4 }}>
        <Typography variant="h5">{surveyCollection ? 'Edit' : 'New'} Survey</Typography>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={hasErrors('name')}
              helperText={errors.name}
              size="small"
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} spacing={2}>
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              error={hasErrors('description')}
              helperText={errors.description}
              size="small"
              variant="outlined"
              fullWidth
              multiline
              minRows={3}
            />
          </Grid>
          <Grid item xs={12} spacing={2}>
            <TextField
              label="Tableau URL"
              value={tableauUrl}
              onChange={(e) => setTableauUrl(e.target.value)}
              error={hasErrors('tableau_url')}
              helperText={errors.tableau_url}
              size="small"
              variant="outlined"
              fullWidth
              minRows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Assign Questionnaires
            </Typography>
            <FormControl fullWidth size="small">
              <Select
                multiple
                fullWidth
                value={selectedSurveys}
                onChange={handleSurveysChange}
                input={<OutlinedInput label="Questionnaires" />}
                renderValue={(selected) => selected.map((id) => allSurveys.find((s) => s.id === id)?.name).join(', ')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
                disabled={isLoading}
              >
                {allSurveys.map((survey) => (
                  <MenuItem key={survey.id} value={survey.id}>
                    <Checkbox checked={selectedSurveys.indexOf(survey.id) > -1} />
                    <ListItemText primary={survey.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', padding: 2, paddingLeft: 4, boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)' }}>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={handleSave} sx={{ minWidth: '150px' }}>
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </Stack>
      </Box>
    </Box>
  );
};

SurveyCollectionsForm.propTypes = {
  surveyCollection: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  confirmCancelRef: PropTypes.object,
};

export default SurveyCollectionsForm;
