import React, { Suspense } from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CacheProvider, NetworkErrorBoundary } from 'rest-hooks';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import AlertError from '../../components/shared/alert_error';

function LinearIndeterminate() {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
  );
}

function AsyncBoundary({ children, fallback = 'loading' }) {
  return (
    <Suspense fallback={fallback}>
      <NetworkErrorBoundary fallbackComponent={AlertError}>{children}</NetworkErrorBoundary>
    </Suspense>
  );
}

export default (props) => {
  return (
    <CacheProvider>
      <AsyncBoundary fallback={<LinearIndeterminate />}>
        <DndProvider backend={HTML5Backend}>{props.children}</DndProvider>
      </AsyncBoundary>
    </CacheProvider>
  );
};
