import { getHeaders } from '../headers';

export const createSurveyCollection = async (survey_collection, baseUrl) => {
  const response = await fetch(`${baseUrl}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ survey_collection }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};

export const updateSurveyCollection = async (survey_collection, baseUrl) => {
  const response = await fetch(`${baseUrl}/${survey_collection.id}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify({ survey_collection }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};

export const deleteSurveyCollection = async (id, baseUrl) => {
  const response = await fetch(`${baseUrl}/${id}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};

export const getAssignableSurveys = async (baseUrl) => {
  const response = await fetch(`${baseUrl}/assignable_surveys`, {
    headers: getHeaders(),
  });
  return response.json();
};
