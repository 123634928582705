import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Select, MenuItem } from '@mui/material';

const LanguageSelector = ({ locales, selectedLocale, onLocaleChange }) => {
  const localeNames = {
    en: 'English',
    es: 'Español',
    fr: 'Français',
    de: 'Deutsch',
    hr: 'Hrvatski',
    id: 'Bahasa Indonesia',
    it: 'Italiano',
    pt: 'Português',
    uk: 'Українська',
  };

  return (
    <FormControl size="small" sx={{ minWidth: 120 }}>
      <Select value={selectedLocale} onChange={(e) => onLocaleChange(e.target.value)} displayEmpty>
        {locales.map((locale) => (
          <MenuItem key={locale} value={locale}>
            {localeNames[locale] || locale}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

LanguageSelector.propTypes = {
  locales: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedLocale: PropTypes.string.isRequired,
  onLocaleChange: PropTypes.func.isRequired,
};

export default LanguageSelector;
