import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  FormHelperText,
  Box,
  Grid,
  Stack,
  TextField,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
  InputLabel,
  Checkbox,
} from '@mui/material';

const SurveyViewersForm = ({ surveyViewer, allUsers, eligibleUsers, onSave, onClose, errors, isLoading = false }) => {
  const [userId, setUserId] = useState(surveyViewer?.user?.id || '');
  const [description, setDescription] = useState(surveyViewer?.description || '');
  const [allowAllSchools, setAllowAllSchools] = useState(surveyViewer?.allow_access_to_all_schools || false);
  const [singleSchoolName, setSingleSchoolName] = useState(surveyViewer?.single_school_name || '');

  const handleSave = async (e) => {
    e.preventDefault();
    const surveyViewerData = {
      id: surveyViewer?.id,
      user_id: userId,
      description,
      allow_access_to_all_schools: allowAllSchools,
      single_school_name: singleSchoolName,
    };
    onSave(surveyViewerData);
  };

  const hasErrors = (prop) => errors && errors[prop]?.length > 0;

  const getSelectedUser = (id) => {
    return allUsers.find((user) => user.id === id);
  };

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Box sx={{ overflowY: 'auto', flexGrow: 1, padding: 4 }}>
        <Typography variant="h5">{surveyViewer ? 'Edit' : 'New'} Survey Viewer</Typography>
        <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              {!surveyViewer?.id ? (
                <Select
                  fullWidth
                  onChange={(e) => setUserId(e.target.value)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 250,
                      },
                    },
                  }}
                  value={userId}
                  disabled={isLoading}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {getSelectedUser(selected).first_name} {getSelectedUser(selected).last_name}
                    </Box>
                  )}
                >
                  {eligibleUsers.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.first_name} {user.last_name}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Typography>
                  {surveyViewer.user.first_name} {surveyViewer.user.last_name}
                </Typography>
              )}
              {errors['user'] && <FormHelperText sx={{ color: '#d32f2f', mx: 0 }}>{errors['user']}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              error={hasErrors('description')}
              helperText={errors.description}
              size="small"
              variant="outlined"
              fullWidth
              multiline
              minRows={3}
            />
            <FormControlLabel
              control={
                <Checkbox checked={allowAllSchools} onChange={(e) => setAllowAllSchools(!allowAllSchools)} name="allow_access_to_all_schools" />
              }
              label="Access All Schools"
            />
            <TextField
              label="Allow This School Only"
              value={singleSchoolName}
              onChange={(e) => setSingleSchoolName(e.target.value)}
              error={hasErrors('single_school_name')}
              helperText={errors.single_school_name}
              size="small"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', padding: 2, paddingLeft: 4, boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)' }}>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={handleSave} sx={{ minWidth: '150px' }}>
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </Stack>
      </Box>
    </Box>
  );
};

SurveyViewersForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  confirmCancelRef: PropTypes.object,
};

export default SurveyViewersForm;
